export const INTERNAL_API_ON_FIND_EVENT = 'internal-api-on-find';

export const INIT_EMBED_EVENT = 'initembed';

export const API_READY_EVENT = 'api-ready';

export const AFTER_REPLACE_EVENT = 'afterreplace';

export const LOADED_MEDIA_DATA_EVENT = 'loaded-media-data';

export const INPUT_CONTEXT_CHANGE_EVENT = 'input-context-change';
