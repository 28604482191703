import { isNil } from '@wistia/type-guards';
import { Color } from './color.js';
import { reportError } from './sentryUtils.ts';

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const colorContrastRatiosByShape = {
  nonText: 3, // 3:1 - https://www.w3.org/TR/WCAG21/#non-text-contrast
  largeText: 3, // 3:1 - https://www.w3.org/TR/WCAG21/#contrast-minimum
  paragraphText: 4.5, // 4.5:1 -  https://www.w3.org/TR/WCAG21/#contrast-minimum
  smallText: 5.5, // We're making this up, but it should be more than the paragraph text
};

export const rgbToHsl = (
  color: Color | number[] | string,
): { hue: number; lightness: number; saturation: number } => {
  let colorArray = color;
  if (color instanceof Color) {
    if (isNil(color.r) || isNil(color.g) || isNil(color.b)) {
      throw new Error('Color does not contain required RGB values');
    }
    colorArray = [color.r, color.g, color.b];
  } else if (typeof color === 'string') {
    const colorInstance = new Color(color);
    if (isNil(colorInstance.r) || isNil(colorInstance.g) || isNil(colorInstance.b)) {
      throw new Error('Color does not contain required RGB values');
    }
    colorArray = [colorInstance.r, colorInstance.g, colorInstance.b];
  }
  const red = colorArray[0] / 255;
  const green = colorArray[1] / 255;
  const blue = colorArray[2] / 255;
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  let hue = 0;
  let saturation = 0;
  const lightness = (max + min) / 2;

  if (max === min) {
    hue = 0;
    saturation = 0;
  }

  const delta = max - min;
  if (delta === 0) {
    return {
      hue,
      saturation,
      lightness: red * 100,
    };
  }
  if (lightness > 0.5) {
    saturation = delta / (2 - max - min);
  } else {
    saturation = delta / (max + min);
  }

  if (max === red) {
    hue = (green - blue) / delta + (green < blue ? 6 : 0);
  } else if (max === green) {
    hue = (blue - red) / delta + 2;
  } else {
    hue = (red - green) / delta + 4;
  }
  hue /= 6;

  return { hue: hue * 360, saturation: saturation * 100, lightness: lightness * 100 };
};

export const getContrast = (foreground: Color | string, background: Color | string): number => {
  // WCAG contrast ratio
  // see http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
  const foregroundColor = new Color(foreground);
  const backgroundColor = new Color(background);
  const l1 = foregroundColor.getRelativeLuminance();
  const l2 = backgroundColor.getRelativeLuminance();
  return l1 > l2 ? (l1 + 0.05) / (l2 + 0.05) : (l2 + 0.05) / (l1 + 0.05);
};

export const adjustColorForProperContrast = (
  foreground: Color | string,
  background: Color | string,
  shape: keyof typeof colorContrastRatiosByShape = 'paragraphText',
): string => {
  const foregroundColor = new Color(foreground);
  const backgroundColor = new Color(background);

  // This shouldn't be a valid scenario, but since the Color class isn't converted to TS yet
  // the r, g, and b properties could theoretically be optional
  if (isNil(foregroundColor.r) || isNil(foregroundColor.g) || isNil(foregroundColor.b)) {
    throw new Error('Color does not contain required RGB values');
  }

  /* eslint-disable @typescript-eslint/no-magic-numbers */
  const initialContrastRatio = getContrast(
    foregroundColor.toHexWithHash(),
    backgroundColor.toHexWithHash(),
  );
  if (foregroundColor.hasAccessibleContrast(backgroundColor, shape)) {
    return foregroundColor.toHexWithHash();
  }

  const { lightness: foregroundColorLightness } = rgbToHsl([
    foregroundColor.r,
    foregroundColor.g,
    foregroundColor.b,
  ]);

  // Does lightening the foreground color slightly increase the contrast ratio?
  let shouldForegroundColorBeLighter =
    foregroundColorLightness < 0.1 ||
    foregroundColor.getContrastRatio(backgroundColor) > initialContrastRatio;

  // If the foreground color is already very light and the initial contrast ratio was 1:1,
  // lightening the foreground color technically would have increased the contrast ratio, but
  // we know it can never be lightened enough to provide sufficient contrast,
  // so we'll set shouldForegroundColorBeLighter to false.
  if (foregroundColorLightness > 0.8 && initialContrastRatio === 1) {
    shouldForegroundColorBeLighter = false;
  }

  let adjustedForegroundColor = new Color(foregroundColor);
  let i = 0;
  while (!adjustedForegroundColor.hasAccessibleContrast(backgroundColor, shape)) {
    i += 1;
    // This is a safety mechanism to prevent getting stuck in an infinite loop.
    // If anyone exceeds 1,000 attempts to find a contrasting color, just return the best option we have
    // and log the colors where we couldn't generate sufficient contrast.
    if (i > 1000) {
      reportError('other', new Error(`Exceeded ${i} attempts to find contrasting color`), {
        key: 'adjust-color-for-proper-contrast',
        foregroundColor: foregroundColor.toHexWithHash(),
        backgroundColor: backgroundColor.toHexWithHash(),
      });
      return `#${adjustedForegroundColor.toHex()}`;
    }
    const adjustedForegroundColorLuminance = adjustedForegroundColor.getRelativeLuminance();
    // If the adjusted foreground color is white or black and the required contrast ratio is more than 3:1,
    // we should just return the adjusted foreground color.
    // Otherwise, if the adjusted foreground color is white or black,
    // reset the adjusted foreground color to the original foreground color and try looking
    // in the opposite direction for a properly contrasting color.
    if (
      i > 1 &&
      (adjustedForegroundColorLuminance === 1 || adjustedForegroundColorLuminance === 0)
    ) {
      if (colorContrastRatiosByShape[shape] > 3) {
        return getContrast('#fff', backgroundColor.toHexWithHash()) >
          getContrast('#000', backgroundColor.toHexWithHash())
          ? '#fff'
          : '#000';
      }
      shouldForegroundColorBeLighter = !shouldForegroundColorBeLighter;
      adjustedForegroundColor = foregroundColor;
    }

    if (shouldForegroundColorBeLighter) {
      adjustedForegroundColor.lighten(1);
    } else {
      adjustedForegroundColor.darken(1);
    }

    /* eslint-enable @typescript-eslint/no-magic-numbers */
  }
  return adjustedForegroundColor.toHexWithHash();
};
