/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-console */
import { root } from './utilities/root.js';
import { WistiaGlobal } from './types/player-api-types.ts';

if (root.Wistia == null) {
  root.Wistia = {} as WistiaGlobal;
}

if (root.Wistia._destructors == null) {
  root.Wistia._destructors = {};
}

if (root.Wistia._initializers == null) {
  root.Wistia._initializers = {};
}

if (root.Wistia._remoteData == null) {
  root.Wistia._remoteData = new Map();
}

if (root.Wistia.api == null) {
  root.Wistia.api = () => {
    console.error('Accessed Wistia.api() before it was initialized');
    return null;
  };
}

if (root.Wistia.defineControl == null) {
  // this will be overwritten in _async.coffee
  root.Wistia.defineControl = () => {
    console.error('Accessed Wistia.defineControl() before it was initialized');
    return null;
  };
}

if (root.Wistia.mixin == null) {
  root.Wistia.mixin = <T>(klass: T, obj: Partial<Record<keyof T, unknown>> = {}) => {
    Object.keys(obj).forEach((key) => {
      if (Object.hasOwn(obj, key)) {
        // eslint-disable-next-line no-param-reassign
        klass[key as keyof T] = obj[key] as unknown as T[keyof T];
      }
    });
  };
}

if (root.Wistia.playlistMethods == null) {
  root.Wistia.playlistMethods = new Map();
}

// will be overwritten in _public_api.coffee
if (root.Wistia.PublicApi == null) {
  root.Wistia.PublicApi = null;
}

// will be overwritten in _remote_data.js
if (root.Wistia.uncacheMedia == null) {
  root.Wistia.uncacheMedia = () => {
    console.error('Accessed Wistia.uncacheMedia() before it was initialized');
    return null;
  };
}

// will be overwritten in _visitor_key.js
if (root.Wistia.VisitorKey == null) {
  root.Wistia.VisitorKey = null;
}

// will be overwritten in _visitor_key.js
if (root.Wistia.visitorKey == null) {
  root.Wistia.visitorKey = null;
}

if (root.Wistia.wistia == null) {
  // will be overwritten in WistiaPlayer.tsx
  root.Wistia.wistia = undefined;
}

if (root.Wistia._mediaDataPromises == null) {
  root.Wistia._mediaDataPromises = {};
}

if (root.Wistia._liveStreamPollingPromises == null) {
  root.Wistia._liveStreamPollingPromises = {};
}

export const Wistia = root.Wistia as WistiaGlobal;
