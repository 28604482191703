/* eslint-disable no-void */
/* eslint-disable no-console */
/* eslint-disable no-loop-func */
// Generated by CoffeeScript 1.12.7
import { doTimeout } from 'utilities/timeout-utils.js';

// eslint-disable-next-line import/no-mutable-exports
let StopGo;

(function () {
  let slice = [].slice;

  StopGo = (function () {
    function StopGo() {
      this._queue = [];
      this._green = false;
      this._lock = false;
      this.allInOne = (function (_this) {
        return function () {
          return _this._allInOne.apply(_this, arguments);
        };
      })(this);
      this.defineMethodsOn(this.allInOne);
      return this.allInOne;
    }

    StopGo.prototype.defineMethodsOn = function (obj) {
      let getType;
      let k;
      let ref;
      let results;
      let v;
      getType = {};
      ref = this;
      results = [];
      for (k in ref) {
        v = ref[k];
        results.push(
          (function (_this) {
            return function (k, v, klass) {
              if (getType.toString.call(v) === '[object Function]') {
                return (obj[k] = function () {
                  return klass[k].apply(klass, arguments);
                });
              }
            };
          })(this)(k, v, this),
        );
      }
      return results;
    };

    StopGo.prototype.flush = function () {
      let args;
      let fn;
      args = arguments.length >= 1 ? slice.call(arguments, 0) : [];
      if (!this._green) {
        return this;
      }
      this._lock = true;
      while (this._queue.length > 0) {
        fn = this._queue.shift();
        if (args.length || !this.goArgs) {
          this.tryAndReport(() => {
            return fn.apply(null, args);
          });
        } else {
          this.tryAndReport(
            (function (_this) {
              return function () {
                return fn.apply(null, _this.goArgs);
              };
            })(this),
          );
        }
        if (this._block || !this._green) {
          break;
        }
      }
      this._lock = false;
      return this;
    };

    StopGo.prototype._debug = function () {
      return typeof console !== 'undefined' && console !== null
        ? console.log.apply(console, arguments)
        : void 0;
    };

    StopGo.prototype.synchronize = function () {
      let fn;
      let fns;
      let j;
      let len;
      fns = arguments.length >= 1 ? slice.call(arguments, 0) : [];
      for (j = 0, len = fns.length; j < len; j++) {
        fn = fns[j];
        this.synchronizeFn(fn);
      }
      return this;
    };

    StopGo.prototype.synchronizeFn = function (fn) {
      let stopBlocking;
      let stopBlockingTimeout;
      let wrapperFn;
      stopBlockingTimeout = null;
      stopBlocking = (function (_this) {
        return function () {
          clearTimeout(stopBlockingTimeout);
          _this._block = false;
          return _this.go();
        };
      })(this);
      wrapperFn = (function (_this) {
        return function () {
          _this._block = true;
          _this.stop();
          return fn(stopBlocking);
        };
      })(this);
      stopBlockingTimeout = setTimeout(
        (function (_this) {
          return function () {
            _this._debug('StopGo#synchronize timed out', fn);
            return stopBlocking();
          };
        })(this),
        5000,
      );
      this.runFn(wrapperFn);
      return this;
    };

    StopGo.prototype.tryAndReport = function (fn) {
      let e;
      try {
        return fn();
      } catch (error) {
        e = error;
        if (e.stack) {
          if (typeof console !== 'undefined' && console !== null) {
            console.log(e.message);
          }
          return typeof console !== 'undefined' && console !== null ? console.log(e.stack) : void 0;
        }
        return typeof console !== 'undefined' && console !== null ? console.log(e) : void 0;
      }
    };

    StopGo.prototype.clearSynchronized = function () {
      this.setQueue([]);
      this._block = false;
      return (this._green = true);
    };

    StopGo.prototype.filter = function (cond) {
      let fn;
      this._queue = function () {
        let j;
        let len;
        let ref;
        let results;
        ref = this._queue;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          fn = ref[j];
          if (cond(fn)) {
            results.push(fn);
          }
        }
        return results;
      }.call(this);
      return this;
    };

    StopGo.prototype.push = function (fn) {
      this._queue.push(fn);
      return this;
    };

    StopGo.prototype.go = function () {
      let args;
      args = arguments.length >= 1 ? slice.call(arguments, 0) : [];
      this._green = true;
      this.goArgs = args;
      this.flush.apply(this, args);
      return this;
    };

    StopGo.prototype.stop = function () {
      this._green = false;
      return this;
    };

    StopGo.prototype.run = function () {
      let fn;
      let fns;
      let j;
      let len;
      fns = arguments.length >= 1 ? slice.call(arguments, 0) : [];
      for (j = 0, len = fns.length; j < len; j++) {
        fn = fns[j];
        this.runFn(fn);
      }
      return this;
    };

    StopGo.prototype.then = function () {
      return this.run.apply(this, arguments);
    };

    StopGo.prototype.runFn = function (fn) {
      if (this._green && !this._block) {
        if (this._lock) {
          this.tryAndReport(fn);
        } else {
          this.push(fn);
          this.flush();
        }
      } else {
        this.push(fn);
      }
      return this;
    };

    StopGo.prototype.remove = function () {
      let fn;
      let fns;
      let j;
      let len;
      fns = arguments.length >= 1 ? slice.call(arguments, 0) : [];
      for (j = 0, len = fns.length; j < len; j++) {
        fn = fns[j];
        this.removeFn(fn);
      }
      return this;
    };

    StopGo.prototype.removeFn = function (targetFn) {
      this.filter((fn) => {
        return targetFn !== fn;
      });
      return this;
    };

    StopGo.prototype.setQueue = function (newQueue) {
      this._queue = newQueue;
      return this;
    };

    StopGo.prototype.getQueue = function () {
      return this._queue;
    };

    StopGo.prototype._allInOne = function () {
      let arg;
      let rest;
      // eslint-disable-next-line no-unused-expressions, no-sequences
      (arg = arguments[0]), (rest = arguments.length >= 2 ? slice.call(arguments, 1) : []);
      if (arg === true) {
        return this.go.apply(this, rest);
      }
      if (arg === false) {
        return this.stop();
      }
      if (arg != null) {
        if (arg instanceof Array) {
          return this.run.apply(this, arg);
        }
        if (typeof arg === 'string') {
          return this[arg].apply(this, rest);
        }
        return this.run.apply(this, arguments);
      }
      return this._green;
    };

    return StopGo;
  })();

  StopGo.when = function () {
    let fn1;
    let j;
    let lastFn;
    let len;
    let ref;
    let result;
    let stopGo;
    let stopGos;
    stopGos = arguments.length >= 1 ? slice.call(arguments, 0) : [];
    result = new StopGo();
    lastFn = function () {
      return result.go.apply(result, arguments);
    };
    ref = stopGos.reverse();
    fn1 = function (theFn, stopGo) {
      return (lastFn = function () {
        return stopGo(function () {
          return theFn.apply(null, arguments);
        });
      });
    };
    for (j = 0, len = ref.length; j < len; j++) {
      stopGo = ref[j];
      fn1(lastFn, stopGo);
    }
    lastFn();
    return result;
  };

  StopGo._drainStopGosAsync = function (prefix, stopGos, i) {
    let stopGo;
    if (i == null) {
      i = 0;
    }
    if (stopGos.length < 1) {
      return;
    }
    stopGo = stopGos.shift();
    stopGo.run(() => {
      return StopGo._drainStopGosAsync(prefix, stopGos, i + 1);
    });
    doTimeout(
      `${prefix}.${i}`,
      () => {
        return stopGo.go();
      },
      0,
    );
    return stopGo;
  };

  // eslint-disable-next-line prettier/prettier
}.call(this));

export { StopGo };
