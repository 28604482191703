// player.js is the "Wistia Library." It has all the features we use to
// embed a video.
import '../embeds/wistiaPlayer/WistiaPlayer.tsx';
import { dynamicImport } from '../utilities/dynamicImport.ts';

// If player.js was not loaded via E-v1.js and a wistia <iframe> embed is on the page,
// we'll never end up loading the public api and iframe api because those are
// only loaded when a <wistia-player> element is initialized. In the case of an
// <iframe>, we don't have a <wistia-player> element outside that <iframe> to initialize.
//
// Instead, in that case we'll load the files we need here so that we
// have the functionality we need to interact with that wistia <iframe>.
(() => {
  const wistiaIframes = document.querySelectorAll('iframe.wistia_embed,iframe[name=wistia_embed]');
  if (wistiaIframes.length > 0) {
    const hasWebComponentIframe = [...wistiaIframes].some((iframe) => {
      const iframeUrl = new URL(iframe.src);
      const searchParams = new URLSearchParams(iframeUrl.search);
      const iframeUrlParams = Object.fromEntries(searchParams.entries());

      return iframeUrlParams.web_component === 'true';
    });

    if (hasWebComponentIframe) {
      // IframeApi is dependent on PublicApi, so we need to load PublicApi first
      dynamicImport('assets/external/publicApi.js').then(() => {
        dynamicImport('assets/external/iframeApi.js');
      });
    }
  }
})();
