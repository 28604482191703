/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { Interpolation } from 'interpolation.js';

(function (Wistia) {
  if (Wistia.Interpolation) {
    return;
  }
  return (Wistia.Interpolation = Interpolation);
})(window.Wistia);
