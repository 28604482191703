import { getLocalStorage, updateLocalStorage } from 'utilities/namespacedLocalStorage.js';

const BASICALLY_ENDED_THRESHOLD = 5;
const BASICALLY_ENDED_THRESHOLD_AS_PERCENTAGE = 0.05;
const DURATION_THRESHOLD = 300;
const NEAR_BEGINNING_THRESHOLD = 10;
const NEAR_BEGINNING_THRESHOLD_AS_PERCENTAGE = 0.1;

export const getLocalStorageKey = (hashedId) => {
  return `wistia-video-progress-${hashedId}`;
};

export const getLastTime = (hashedId) => {
  return getLocalStorage(getLocalStorageKey(hashedId)).lastTime;
};

export const setLastTime = (hashedId, time) => {
  updateLocalStorage(getLocalStorageKey(hashedId), (obj) => {
    obj.lastTime = time;
  });
};

export const getResumableKeyForVideo = (hashedId) => {
  return getLocalStorage(getLocalStorageKey(hashedId)).resumableKey;
};

export const setResumableKeyForVideo = (hashedId, key) => {
  updateLocalStorage(getLocalStorageKey(hashedId), (obj) => {
    obj.resumableKey = key;
  });
};

export const atOrNearEnd = (hashedId, duration) => {
  return (getLastTime(hashedId) || 0) + BASICALLY_ENDED_THRESHOLD > duration;
};

export const atOrNearEndAsPercentage = (hashedId, duration) => {
  const lastTimeAsPercentage = getLastTime(hashedId) / duration || 0;
  return lastTimeAsPercentage + BASICALLY_ENDED_THRESHOLD_AS_PERCENTAGE > 1;
};

export const atOrNearBeginning = (hashedId) => {
  const time = getLastTime(hashedId) || 0;
  return time < NEAR_BEGINNING_THRESHOLD;
};

export const atOrNearBeginningAsPercentage = (hashedId, duration) => {
  const percentWatched = getLastTime(hashedId) / duration || 0;
  return percentWatched < NEAR_BEGINNING_THRESHOLD_AS_PERCENTAGE;
};

export const shorterThanResumableThreshold = (duration) => {
  return duration < DURATION_THRESHOLD;
};

export const withinResumableTime = (hashedId, duration) => {
  return !atOrNearBeginning(hashedId) && !atOrNearEnd(hashedId, duration);
};

export const safelyGetPercentWatched = (lastTime, duration) => {
  if (lastTime == null || !duration) {
    return 0;
  }
  return Math.min(Math.ceil((100 * lastTime) / duration), 100);
};
